import React from 'react';
import { useQuery } from '@apollo/client';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GET_GODO_BANNER_INFO } from '@api';
import { LeftShadowArrowIcon, RightShadowArrowIcon } from 'src/assets/icons';
import { ArrowNext, ArrowPrev, BannerContainer, BannerContents, BannerSkelton, PagingButton, PagingItem, PagingWrapper, Wrapper } from './ResponsiveSlideBanner.styles';
import { normalizeSrc } from './ResponsiveSlideBanner.constants';
import { IResponsiveSlideBannerProps } from './ResponsiveSlideBanner.types.';

/**
 * 반응형 슬라이드 배너를 만들어 주는 컴포넌트 입니다.
 * bannerCode를 기준으로 자동 생성 되며, children으로 넣은 항목을 슬라이드 뒷단에 배치합니다.
 * @param props
 * @returns 슬라이드 배너 컴포넌트
 */
export const ResponsiveSlideBanner = ({ bannerCode, isBorder = true, skeletonHeight = 120, children, ...rest }: IResponsiveSlideBannerProps): React.ReactElement => {
  // 배너 정보 GET
  const {
    data: bannerItem,
    loading,
    error,
  } = useQuery(GET_GODO_BANNER_INFO, {
    variables: {
      bannerCode: bannerCode,
    },
  });

  // 배너 정보 Data
  const bannerData = bannerItem?.getGodoBannerInfo;

  if (error || loading || !bannerData) return <BannerSkelton data-height={skeletonHeight} />;

  /**
   * dots           : 캐러셀이미지가 몇번째인지 알려주는 점을 보여줄지 정한다.
   * arros          : 캐러셀 이미지 방향 전환을 위한 사이드 버튼
   * infinite       : loop를 만들지(마지막 이미지-처음 이미지-중간 이미지들-마지막 이미지)
   * speed          : 애니메이션의 속도, 단위는 milliseconds
   * slidesToShow   : 한번에 몇개의 슬라이드를 보여줄 지
   * slidesToScroll : 한번 스크롤시 몇장의 슬라이드를 넘길지
   * draggable      : 배너 드래그 여부
   * autoplay       : 자동 슬라이드 여부
   * fade           : 화면 전환 Fade 여부
   * prevArrow      : 이전으로 가기 버튼 커스터마이징
   * nextArrow      : 다음으로 가기 버튼 커스터마이징
   * beforeChange   : 화면 전환 전 이벤트
   * appendDots     : 하단 네비게이션 생성
   * customPaging   : 커스텀 dots
   */
  const settings = {
    dots: bannerData.isNaviButton,
    arrows: bannerData.isSideButton,
    infinite: true,
    speed: bannerData.speed,
    autoplaySpeed: bannerData.time * 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    fade: bannerData.effect === 'fade' ? true : false,
    prevArrow: (
      <ArrowPrev>
        <LeftShadowArrowIcon size={48} weight={'thin'} color={bannerData?.sideButtonColor || '#fff'} />
      </ArrowPrev>
    ),
    nextArrow: (
      <ArrowNext>
        <RightShadowArrowIcon size={48} weight={'thin'} color={bannerData?.sideButtonColor || '#fff'} />
      </ArrowNext>
    ),
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => {
      return (
        <PagingWrapper bottom={bannerData.naviButtonSize - 11}>
          {dots.map((item, index) => (
            <PagingItem activeColor={bannerData.naviButtonActiveColor} active={item.props.className === 'slick-active'} key={index}>
              {item.props.children}
            </PagingItem>
          ))}
        </PagingWrapper>
      );
    },
    // eslint-disable-next-line react/display-name
    customPaging: () => {
      const pagingButtonType = bannerData.naviButtonType;
      let pagingButtonRadius = '50%';
      const pagingButtonSize = bannerData.naviButtonSize + 2;

      // 네비게이션 버튼 종류
      pagingButtonRadius = pagingButtonType === 'circle' ? '50%' : pagingButtonType === 'radius' ? '20%' : '0px';

      return <PagingButton size={pagingButtonSize} color={bannerData.naviButtonColor} radius={pagingButtonRadius} />;
    },
  };

  return (
    <Wrapper isBorder={isBorder} {...rest}>
      <Slider {...settings}>
        {(bannerData?.bannerList ?? []).map((bannerInfo, idx) => (
          <a
            key={idx}
            href={bannerInfo.linkUrl}
            className={`main__banner${idx + 1}`}
            target={bannerInfo.isBlank ? '_blank' : '_self'}
            data-mixpanel-action="click"
            data-mixpanel-evt="Click Banner"
            data-mixpanel-type="Slide Banner"
            data-mixpanel-link={bannerInfo.linkUrl}
            rel="noreferrer"
          >
            <BannerContainer color={bannerInfo.color}>
              <BannerContents bannerMaxWidth={bannerData.isPx ? bannerData.width + 'px' : '100%'}>
                <img src={`https://www.acon3d.com/cdn-cgi/image/f=webp,q=95/${normalizeSrc(bannerInfo.imageUrl)}`} alt="mainTopBanner" />
              </BannerContents>
            </BannerContainer>
          </a>
        ))}
        {children}
      </Slider>
    </Wrapper>
  );
};
